<template>
  <div style="width:100%;height:100%;position:fixed;top:0;left:0;z-index:10;">
    <!--エフェクト WebGL 9 : ダジャレ後のエフェクト（差分）-->
    <!-- <div id="renderer9" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:1"></div> -->
    <canvas id="canvas" width="600" height="400"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";
//  参考にするサイトhttps://ics.media/tutorial-three/renderer_resize/
/* eslint-disable */
export default {
  name: "RotateBox",
  // data() {
  // },
  mounted() {
    // ページが読み込まれたらinitを起動する。卵の表示をする。
    window.addEventListener('load', init);

    // WebGLの設定をする。
    function init() {
      // WebGLを扱うレンダラー「WebGLRenderer」を指定する。
      const renderer = new THREE.WebGLRenderer({
        // #canvasのIDを持つcanvasタグを指定する。
        canvas: document.querySelector('#canvas'),
        // アンチエアリスを実行して、線を滑らかに表示する。
        antialias: true,
        // 背景を透過する。
        alpha: true,
      });
      renderer.gammaInput = true;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;

      // シーンを作成する。scene＝「画面」（THREE.Sceneクラス）
      const scene = new THREE.Scene();

      // カメラを作成する。
      const camera = new THREE.PerspectiveCamera(45, 1.0);
      camera.position.set(0, -3, +1090);

      //卵を作る
      var points = [];
      for (var deg = 0; deg <= 180; deg += 6) {
          var rad = Math.PI * deg / 180;
          var point = new THREE.Vector3((0.72 * 213 + 0.08 * 220 * Math.cos(rad)) * Math.sin(rad), -200 * Math.cos(rad));
          //var point = new THREE.Vector2((0.72 * 200 + 0.08 * 200 * Math.cos(rad)) * Math.sin(rad), -200 * Math.cos(rad));
          points.push(point);
      }
      const geometry = new THREE.LatheBufferGeometry( points, 150 ); //形状（ジオメトリ）の作成 スクリーンまでの距離,
      //マテリアルの指定
      //参考https://matorel.com/archives/602
      var material = new THREE.MeshStandardMaterial({//
          color: 0xDAA186,//拡散反射の色。オブジェクトのベース色。
          specular: 0xFFFFFF,//16進数で光の色を表す
          //参考:https://nogson2.hatenablog.com/entry/2017/05/15/184849
          //emissive: 0xDADADA,
          shininess: 20000 //鏡面反射光
      });
      const box = new THREE.Mesh(geometry, material); //オブジェクトの作成
      scene.add(box);
      // Boxの影を有効化
      box.castShadow = true;

      // //環境光源を設定する
        // 照明を作成
        const light = new THREE.SpotLight(0xFFFFFF, 2, 100, Math.PI / 4, 1);
        // ライトに影を有効にする
        light.castShadow = true;
        scene.add(light);
        var directionalLight = new THREE.DirectionalLight(0xfff1d7,0.80);
        directionalLight.position.set(-6, 11, 10);
        var hemisphereLight = new THREE.HemisphereLight( 0xd7fbff, 0x7e94a8, 0.7 );
        scene.add( hemisphereLight );
        var directionalLight2 = new THREE.DirectionalLight( 0xffffff );
        directionalLight2.position.set( 0, 0.4, 0.6 );
        scene.add( directionalLight2 );
        var directionalLight9 = new THREE.SpotLight( 0xffffff80 );
        directionalLight9.position.set( 0, 1000000, -100 );
        scene.add( directionalLight9 );

      tick();

      // 毎フレーム時に実行されるループイベントです
      function tick() {
        // レンダリング
        renderer.render(scene, camera);
        requestAnimationFrame(tick);
      }

      // 初期化のために実行
      onResize();
      // リサイズイベント発生時に実行
      window.addEventListener('resize', onResize);

      function onResize() {
        // サイズを取得
        const width = window.innerWidth;
        const height = window.innerHeight;

        // レンダラーのサイズを調整する
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);

        // カメラのアスペクト比を正す
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      }


      function createPlane(){
        var plane = new THREE.Mesh(new THREE.PlaneGeometry(200, 200), new THREE.MeshLambertMaterial({color: "lawngreen"}));
        plane.rotation.x = Math.PI * 3 / 2;
        return plane;
      }
      var plane = createPlane();
      scene.add(plane);
    }
  },
  methods: {
    animate() {
      //
    }
  }
};
/* eslint-enable */
</script>

<style>
canvas#canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>