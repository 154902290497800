<template>
<div id="first" style="width:100%;height:100%;transition:opacity 600ms;opacity:0">
<!--生まれる前のエフェクト2パターン-->
  <!--エフェクト WebGL 1 : 黄色い背景でキラキラした浮遊物を右往左往させた-->
  <div id="renderer4" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
  <!--エフェクト WebGL 2 : キラキラを単純に散らしてみる。-->
  <div id="renderer12" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
<!--============================================================-->

<!--体温を測った時のエフェクトパターン3つ-->
  <!--エフェクト WebGL 1 : 体温が標準または低め-->
  <div id="renderer0" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
  <!--エフェクト WebGL 2 : 体温が標準より高め-->
  <div id="renderer5" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
  <!--エフェクト WebGL 3 : お前は熱だ。今日は休んどけ-->
  <div id="renderer10" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
<!--============================================================-->

<!--温まった時-->
  <!--エフェクト WebGL  : 煙が出てくるよ-->
  <div id="renderer11" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
<!--============================================================-->

<!--検温前に冷えている-->
  <!--エフェクト WebGL 1 : 24時間体温未入力時のエフェクト-->
  <div id="renderer7" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
<!--============================================================-->

<!--検温後に冷える-->
  <!--エフェクト WebGL 1 : パチパチ漂わせる-->
  <div id="renderer8" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
  <!--冷えているイメージ2：雪を振らせてみる-->
  <div id="renderer13" style="display:inline-block;position:fixed;top:0;left:0;width:100%;height:100%;transition:opacity 600ms;opacity:0"></div>
<!--============================================================-->


  <!--最上面レイヤー：スクリーン-->
  <div style="display:inline-block;width:100%;height:100%;position:relative">
    <!--WebGLの卵-->
    <Egg></Egg>
      <!--=========================================
                    ホーム画面
      =========================================-->
        <div style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:20;">
              <div style="width:50vh;height:5%;margin:7% auto 0;text-align:left;">
                <div id="dayday2" style="display: none;">
                  <p style="font-size: 3.5vh;">1日目</p>
                </div>
                <div id="dayday" style="display: none;">
                  <p style="font-size: 3.5vh;">{{DataDay}}日目</p>
                </div>
              </div>
            <div style="width:100%;height:12.3%;position:absolute;bottom:16%;left:0;z-index:10;">
                <img @click="$Enter$Temperature" class="btn" src="../assets/input.svg" style="height:100%;cursor:pointer;">
            </div>
            <div style="width:100%;height:12%;position:absolute;bottom:3%;left:0;z-index:10;">
                <img @click="PageGraphBtnOn" class="btn" src="../assets/btn01.svg" style="height:100%;cursor:pointer;">
                <img @click="PageOn" class="btn" src="../assets/btn02.svg" style="height:100%;cursor:pointer;">
                <img @click="PageHiyokoBtnOn" class="btn" src="../assets/btn03.svg" style="height:100%;cursor:pointer;">
            </div>
        </div>
        <!--=========================================
                        体温グラフ
        =========================================-->
          <transition name="show2">
            <div v-if="PageGraph" style="display:block;width:100%;height:100%;background:#ffffff;position:absolute;top:0;left:0;z-index:100;">
              <div style="width:56.15vh;height:100%;margin:0 auto;position:relative">
                <div style="width:100%;height:3.3%;position:absolute;top:5.8%;left:0;z-index:10;">
                    <img src="../assets/test_h2.svg" style="height:100%;">
                </div>
                <div style="width:100%;height:5%;position:absolute;top:8%;left:0;z-index:10;">
                  <div style="width:calc((100% - 50px));margin:0 auto;">
                    <chart :chart-data="datacollection" :options="options" :height="197" :width="335" v-if="showChild" v-bind:variable="$Data$Temperature" v-bind:heikin="taionhyouziheikin"></chart>
                  </div>
                </div>
                <div style="display:block;width:100%;height:43%;position:absolute;top:42%;left:0;z-index:10;">
                  <p style="display:block;width:calc((100% - 108px));font-size:2.4vh;padding:2.3vh; margin:4% auto 0;background: #000000;color:#fff;">
                    平均体温
                  </p>
                  <p style="display:block;width:calc((100% - 110px));font-size:3vh;; padding:2.3vh; margin:0 auto 4%;color:#000;border:1px solid #000;">
                    {{taionhyouziheikin}}℃
                  </p>
                  <p style="display:block;width:calc((100% - 108px));font-size:2.4vh;; padding:2.3vh; margin:4% auto 0;background:#000000;color:#fff;">
                    今日の体温
                  </p>
                  <p style="display:block;width:calc((100% - 110px));font-size:3vh;; padding:2.3vh; margin:0 auto 4%;color:#000;border:1px solid #000;">
                    {{taion}}℃
                  </p>
                </div>
                <div style="width:100%;height:1.9%;position:absolute;bottom:7.5%;left:0;z-index:10;">
                  <div style="display:block;width:100%;height:100%;">
                    <img src="../assets/i_p_close.svg" class="close_img" @click="PageGraphBtnOn" style="height:100%;cursor:pointer">
                  </div>
                </div>
              </div>
            </div>
          </transition>

<!--=========================================
            ひよこ図鑑
=========================================-->
  <transition name="show2">
    <div v-if="PageHiyoko" style="display:block;width:100%;height:100%;background:#ffffff;position:absolute;top:0;left:0;z-index:100;">
      <div style="width:56.15vh;height:100%;margin:0 auto;position:relative">
        <div style="width:100%;height:3.3%;position:absolute;top:5.8%;left:0;z-index:10;">
            <img src="../assets/i_h2_hiyoko.svg" style="height:100%;">
        </div>
        <div style="display:block;width:100%;height:60%;position:absolute;top:8%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;position:relative;">
            <div style="display:block;width:100%;height:93.8%;position: absolute;bottom: 0;left: 0;z-index: 100;">
                <ul style="display:block;width:84.8%;height:100%;margin:0 auto;">
                  <li class="box_item">
                    <img :src="this.chara" class="box_item_img">
                    <p class="box_day">{{this.lasday}}</p>
                  </li>
                </ul>
            </div>
          </div>
        </div>
        <div style="width:100%;height:1.9%;position:absolute;bottom:7.5%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img" @click="PageHiyokoBtnOn" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
    </div>
  </transition>

<!--=========================================
                体温入力１
=========================================-->

  <div style="width:56vh;height:100%;margin:0 auto;position:relative" v-if="PageEntry">
    <transition name="show_m">
      <div style="display:block;width:80%;height:47vh;background:#ffffff;position: absolute;top: 50%;left: 50%;z-index: 200;transform: translateX(-50%)translateY(-50%);">
        <div style="width:100%;height:7%;position:absolute;top:12%;left:0;z-index:10;">
          <img src="../assets/i_h2_function3.svg" style="display:inline-block;height:100%;text-align:center;">
        </div>
        <div style="width:100%;height:20%;position:absolute;top:16%;left:0;z-index:10;">
          <input v-model="taion" id="startTime" type="number" min="32" max="40" name="appt-time" style="width:80%;padding:4%;font-size:2rem;margin:12% auto;">
        </div>
        <div style="width:100%;height:3.5vh;position:absolute;bottom:40%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/inputbtn2.svg" style="width:66.7%;margin:0 auto;" @click="$Enter$Temperature$On" target="_blank">
          </div>
        </div>
        <div style="width:100%;height:4%;position:absolute;bottom:12%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img"  @click="$Enter$Temperature" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
            </transition>
  </div>

  <!-- 黒い背景-->
  <transition name="show_m_bg">
    <div v-if="PageEntry" @click="$Enter$Temperature" style="width:2000%;height:2000%;background:#00000060;position: absolute;top: 50%;left: 50%;z-index: 180;transform: translateX(-50%)translateY(-50%);"></div>
  </transition>

  <div v-show="born" style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:1000;transition:opacity 600ms;" @click="PageEntryBtn10On"></div>
  <img v-show="born" src="../assets/born.svg" style="width:80%;position:absolute;top:20%;z-index:800;left:10%;transition-delay:0.3s;transition:opacity 600ms;">
  <img v-show="born" src="../assets/born2.png" style="width:60%;position:absolute;top:50%;z-index:800;left:50%;transform:translateX(-50%)translateY(-50%);transition-delay:0.3s;transition:opacity 600ms;">

  <div v-if="PageEntry3" style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:1000;transition:opacity 600ms;" @click="PageEntryBtn6On"></div>
  <img v-if="PageEntry3" src="../assets/bois1.svg" style="height:13%;position:absolute;top:13%;z-index:100;left:10%;transition-delay:0.3s;  transform: translateX(0%);transition:opacity 600ms;">

  <div v-if="PageEntry4" style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:1000;transition:opacity 600ms;" @click="PageEntryBtn7On"></div>
  <img v-if="PageEntry4" src="../assets/bois2.svg" style="height:13%;position:absolute;top:13%;z-index:100;left:10%;transition-delay:0.3s;  transform: translateX(16%);transition:opacity 600ms;">

  <div v-show="PageEntry1" style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:1000;transition:opacity 600ms;" @click="PageEntryBtn5On"></div>
  <img v-show="PageEntry1" src="../assets/textatatamaru.svg" style="width:80%;position:absolute;top:20%;z-index:800;left:10%;transition-delay:0.3s;transition:opacity 600ms;">

<!--=========================================
                体温入力２
=========================================-->
  <div style="width:56vh;height:100%;margin:0 auto;position:relative" v-if="PageEntryd">
    <transition name="show_m">
      <div style="display:block;width:80%;height:45vh;background:#ffffff;position: absolute;top: 50%;left: 50%;z-index: 200;transform: translateX(-50%)translateY(-50%);">
        <div style="width:100%;height:7%;position:absolute;top:12%;left:0;z-index:10;">
          <img src="../assets/i_h2_function4.svg" style="display:inline-block;height:100%;text-align:center;">
        </div>
        <div style="width:100%;height:6vh;position:absolute;top:40%;left:0;z-index:10;">
          <img src="../assets/text2.svg" style="height:100%;;margin:0 auto;" @click="$Enter$Temperature$On" target="_blank">
        </div>
        <div style="width:100%;height:4%;position:absolute;bottom:12%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img"  @click="$Enter$Temperature" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 黒い背景-->
  <transition name="show_m_bg">
    <div v-if="PageEntryd" @click="$Enter$Temperature" style="width:2000%;height:2000%;background:#00000060;position: absolute;top: 50%;left: 50%;z-index: 180;transform: translateX(-50%)translateY(-50%);"></div>
  </transition>



<!--=========================================
            通知１
=========================================-->
  <div style="width:56vh;height:100%;margin:0 auto;position:relative" v-if="PageOnLINE">
    <transition name="show_m">
      <div style="display:block;width:80%;height:45vh;background:#ffffff;position: absolute;top: 50%;left: 50%;z-index: 200;transform: translateX(-50%)translateY(-50%);">
        <div style="width:100%;height:7%;position:absolute;top:12%;left:0;z-index:10;">
          <img src="../assets/i_h2_function.svg" style="display:inline-block;height:100%;text-align:center;">
        </div>
        <div style="width:100%;height:6vh;position:absolute;top:30%;left:0;z-index:10;">
          <img src="../assets/line_text.svg" style="height:100%;;margin:0 auto;">
        </div>
        <div style="width:100%;height:3.5vh;position:absolute;bottom:40%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <a href="https://line.me/R/ti/p/@933hghqe" target="_blank" @click="$Enter$Temperature3">
              <img src="../assets/line_btn.svg" style="width:66.7%;margin:0 auto;">
            </a>
          </div>
        </div>
        <div style="width:100%;height:4%;position:absolute;bottom:12%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img" @click="PageOn" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 黒い背景-->
  <transition name="show_m_bg">
    <div v-if="PageOnLINE" @click="PageOn" style="width:2000%;height:2000%;background:#00000060;position: absolute;top: 50%;left: 50%;z-index: 180;transform: translateX(-50%)translateY(-50%);"></div>
  </transition>


<!--=========================================
            通知２
=========================================-->
  <div style="width:56vh;height:100%;margin:0 auto;position:relative" v-if="PageOn2LINE">
    <transition name="show_m">
      <div style="display:block;width:80%;height:45vh;background:#ffffff;position: absolute;top: 50%;left: 50%;z-index: 200;transform: translateX(-50%)translateY(-50%);">
        <div style="width:100%;height:7%;position:absolute;top:12%;left:0;z-index:10;">
          <img src="../assets/i_h2_function2.svg" style="display:inline-block;height:100%;text-align:center;">
        </div>
        <div style="width:100%;height:6vh;position:absolute;top:16%;left:0;z-index:10;">
          <input v-model="message" id="startTime" type="time" value="00:00" name="appt-time" style="display:block;width:80%;padding:4%;font-size:2rem;margin:12% auto;">
        </div>
        <div style="width:100%;height:3.5vh;position:absolute;bottom:40%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <a href="https://line.me/R/ti/p/@933hghqe" target="_blank" @click="$Enter$Temperature4">
              <img src="../assets/inputbtn.svg" style="width:66.7%;margin:0 auto;">
            </a>
          </div>
        </div>
        <div style="width:100%;height:4%;position:absolute;bottom:12%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img" @click="PageOn4" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 黒い背景-->
  <transition name="show_m_bg">
    <div v-if="PageOn2LINE" @click="PageOn4" style="width:2000%;height:2000%;background:#00000060;position: absolute;top: 50%;left: 50%;z-index: 180;transform: translateX(-50%)translateY(-50%);"></div>
  </transition>


<!--=========================================
            通知３
=========================================-->
  <div style="width:56vh;height:100%;margin:0 auto;position:relative" v-if="PageOn3LINE">
    <transition name="show_m">
      <div style="display:block;width:80%;height:45vh;background:#ffffff;position: absolute;top: 50%;left: 50%;z-index: 200;transform: translateX(-50%)translateY(-50%);">
        <div style="width:100%;height:7%;position:absolute;top:12%;left:0;z-index:10;">
          <img src="../assets/text3.svg" style="display:inline-block;height:100%;text-align:center;">
        </div>
        <div style="width:100%;height:6vh;position:absolute;top:30%;left:0;z-index:10;">
          <img src="../assets/i_h2_function6.svg" style="height:100%;;margin:0 auto;" @click="$Enter$Temperature$On" target="_blank">
        </div>
        <div style="width:100%;height:3.5vh;position:absolute;bottom:40%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <a href="https://line.me/R/ti/p/@933hghqe" target="_blank" @click="$Enter$Temperature5">
              <img src="../assets/inputbtn.svg" style="width:66.7%;margin:0 auto;">
            </a>
          </div>
        </div>
        <div style="width:100%;height:4%;position:absolute;bottom:12%;left:0;z-index:10;">
          <div style="display:block;width:100%;height:100%;">
            <img src="../assets/i_p_close.svg" class="close_img"  @click="PageOn3" style="height:100%;cursor:pointer">
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 黒い背景-->
  <transition name="show_m_bg">
    <div v-if="PageOn3LINE" @click="PageOn3" style="width:2000%;height:2000%;background:#00000060;position: absolute;top: 50%;left: 50%;z-index: 180;transform: translateX(-50%)translateY(-50%);"></div>
  </transition>
  </div>
</div>
</template>




<style lang="scss" scoped>
/* ひよこ一覧 */
.hiyoko_page .h2_img{
  display:inline-block;
  width:28.3%; /* 文字サイズ指定 */
  text-align:center;
}
li.box_item{
  display:inline-block;
  width:30.8%;
  height:27.8%;
  margin: 0 3.8% 3.8% 0
}
li.box_item:nth-child(3n){
  margin: 0;
}
.box_item_img_area{
  display:block;
  width:100%;
}
.box_item_img{
  display:block;
  width:100%;
}
.box_day{
    height:10.3%;
}
/*モーダルの中身*/
.line_modal {
  display: inline-block;
  width:80%;
  background:#ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 200;
  transform: translateX(-50%)translateY(-50%);
  .h2_img{
    display:block;
    position: relative;
    width:26%;
    margin:11.3% auto 0;
  }
  .line_text{
    width:70.7%;
    margin:12% auto;
  }
  .line_btn{
    width:66.7%;
    margin:0 auto;
  }
  .close_img{
    display:block;
    width:13.7%;
    height:auto;
    margin: 7.2% auto 11.3%;
    position: relative;
  }
}
/*モーダルの中身*/
.function_modal {
  display: inline-block;
  width:80%;
  background:#ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 200;
  transform: translateX(-50%)translateY(-50%);
  .h2_img{
    display:block;
    position: relative;
    width:26%;
    margin:11.3% auto 0;
  }
  .form input[type="text"]{
    width:80%;
    height:30%;
    align-items: center;
    display: -webkit-inline-flex;
    font-family: monospace;
    padding-inline-start: 1px;
    cursor: default;
    overflow: hidden;
    padding: 0px;
  }
  .close_img{
    display:block;
    width:13.7%;
    height:auto;
    margin: 7.2% auto 11.3%;
    position: relative;
  }
}



/* ページ背景 白 */
.page{
  display: inline-block;
  width:100%;//変更
  height:100%;
  background:#ffffff;
  padding: 0 20%;
  position: absolute;
  top: 0;
  left: 0;//変更
  z-index: 100;
  transition: opacity .3s ease;
  transform: translateX(-15%);
}
/* ヘッダー */
.h2{
  display:block;
  width:100%;
  position: absolute;
  bottom: 0; /* .headerの下部に設置 */
  left: 0;
  z-index: 100;
}
/* 文字 */
p{
  font-size: 13px;
}
/*フッター*/
.footer{
  display:block;
  width:100%;
  height:2.2%;
  margin-top: 10%;
  position: relative;
}
.close_img{
  display:inline-block;
  height:100%;
  text-align:center;
}





/* 共通・編集中 */
.page_nation{
  display:block;
  width:100%;
  height:3%;
}
.page_nation p{
  display:block;
  margin-top:4.5%;
  text-align:center;
}



/*===================
  ▼  トップページ  ▼
===================*/

.temperature img{
  width: 59.7%;
  height: 12.6%;
  position: absolute;
  top: 66.7%;
  left: 20.3%;
  z-index: 1;
}
.btn_01 img{
  width: 33.1%;
  position: absolute;
  bottom: 2.8%;
  left: 2.1%;
  z-index: 1;
}
.btn_02 img{
  width: 33.1%;
  position: absolute;
  bottom: 2.8%;
  left: 33.6%;
  z-index: 1;
}
.btn_03 img{
  width: 33.1%;
  position: absolute;
  bottom: 2.8%;
  right: 2.1%;
  z-index: 1;
}

/* アニメーション */
.show2-enter-active,
.show2-leave-active {
  transition: opacity 1s;
  transition: all 0.4s;
}
.show2-enter,
.show2-leave-to {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s;
}
.show_m-enter-active,
.show_m-leave-active {
  transition: opacity 1s;
  transition: all 0.4s;
}
.show_m-enter,
.show_m-leave-to {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%)translateY(70%);
  transition: all 0.3s;
}

.show_t-enter-active,
.show_t-leave-active {
  transition: opacity 1s;
  transition: all 0.4s;
}
.show_t-enter,
.show_t-leave-to {
  opacity: 0;
  transition: all 0.3s;
}

.show_m_bg-enter-active,
.show_m_bg-leave-active {
  transition: opacity 1s;
  transition: all 0.4s;
}
.show_m_bg-enter,
.show_m_bg-leave-to {
  opacity: 0;
  transition: all 0.3s;
}
.chart-wrapper {
  width: 85.9% !important;
  height: 28.5% !important;
  margin: auto;
}

.show_t2-enter-active,
.show_t2-leave-active {
  transition: opacity 1s;
  transition: all 0.4s;
  transform: translateY(0px);
}
.show_t2-enter,
.show_t2-leave-to {
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(30px);
}




.btn{transition: .06s ease;}
.btn:active {transform: scale(0.9);transition: .06s;filter: brightness(90%) blur(1px);}
</style>

<script>
/*グラフのコンポーネントファイルをインポートする*/
import Chart from '@/components/Chart.vue';
/*ひよこ図鑑のコンポーネントファイルをインポートする*/
// import Hiyoko from '../components/Hiyoko.vue';
import Egg from "../components/Egg";


export default {
  data: function () {
    return {
      characterlists: '',
      characterdatas: '',
      lassday: JSON.parse(localStorage.getItem("$Data$LastUpdates")),
      lasday: JSON.parse(localStorage.getItem("$Data$Character2")),
      chara: JSON.parse(localStorage.getItem("$Data$Character")),
      //chara: require(JSON.parse(localStorage.getItem("$Data$Character"))),
      PageEntry1: false,
      PageEntry3: false,
      PageEntry4: false,
      born: false,
      //体温を入力→未入力→入力画面の表示非表示
      PageEntry: false,
      //体温を入力→入力済み→入力済みの画面を表示
      PageEntryd: false,
      DataDay: JSON.parse(localStorage.getItem("$Data$Day")),
      PageLINEEntry: false,
      PageFunctionEntry: false,
      PageTimeEntry: false,
      PageENDEntry: false,
      PageEND2Entry: false,
      Num: 0,
      Num2: 0,
      sum: 0,
      message: "",
      HiyokoPage: false,
      GraphPage: false,
      FunctionPage: false,
      FunctionPage2: false,
      LinePageOn: false,
      linenum: 0,
      //animal: 0
      count: 0,
      //ローカルストレージテスト
      PageGraph: false,
      PageHiyoko: false,
      
      PageEntry2: 0,
      
      PageEntry5: false,
      PageEntry6: false,
      taion: 0,
      taionhyouzi: [],
      taionhyouziheikin:  JSON.parse(localStorage.getItem("$Data$Day3")),
      PageOnLINE1: 0,
      PageOnLINE: false,
      PageOnLINEnum: 0,
      PageOnLINE2: false,
      PageOn2LINE: false,
      PageOnLINE3: false,
      PageOn3LINE: false,
      showChild: true,
      hiyokomodal2: false,
      messages: this.taionhyouzi,
      
    }
  },
  /* ▼ ローカルストレージ ▼ */
  watch: {
    count: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('count', this.count);
      },
      deep: true,
    },
    /* グラフページ */
    PageGraph: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageGraph', this.PageGraph);
      },
      deep: true,
    },
    /* ひよこページ */
    PageHiyoko: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageHiyoko', this.PageHiyoko);
      },
      deep: true,
    },
    PageEntry: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageEntry', this.PageEntry);
      },
      deep: true,
    },
    PageEntry1: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageEntry1', this.PageEntry1);
      },
      deep: true,
    },
    PageEntry2: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageEntry2', this.PageEntry2);
      },
      deep: true,
    },
    PageEntry3: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageEntry3', this.PageEntry3);
      },
      deep: true,
    },
    PageOnLINE: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOnLINE', this.PageOnLINE);
      },
      deep: true,
    },
    PageOnLINE1: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOnLINE1', this.PageOnLINE1);
      },
      deep: true,
    },
    PageOnLINEnum: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOnLINEnum', this.PageOnLINEnum);
      },
      deep: true,
    },
    PageOnLINE2: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOnLINE2', this.PageOnLINE2);
      },
      deep: true,
    },
    PageOn2LINE: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOn2LINE', this.PageOn2LINE);
      },
      deep: true,
    },
    PageOnLINE3: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOnLINE3', this.PageOnLINE3);
      },
      deep: true,
    },
    PageOn3LINE: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('PageOn3LINE', this.PageOn3LINE);
      },
      deep: true,
    },
    taion: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('taion', this.taion);
      },
      deep: true,
    },
    taionhyouzi: {
      //数値を書き込む
      handler: function () {
        localStorage.setItem('taionhyouzi', this.taionhyouzi);
      },
      deep: true,
    },
  },
  //記録されたものを読み込む
  mounted: function () {
    this.count = JSON.parse(localStorage.getItem('count'));
    /* グラフページ */
    this.PageGraph = JSON.parse(localStorage.getItem('PageGraph'));
    /* ひよこページ */
    this.PageHiyoko = JSON.parse(localStorage.getItem('PageHiyoko'));
    /* 体温入力ページ */
    this.PageEntry = JSON.parse(localStorage.getItem('PageEntry'));
    /* 体温入力ページ */
    this.PageEntry1 = JSON.parse(localStorage.getItem('PageEntry1'));
    /* 体温入力ページ 入力後 */
    this.PageEntry2 = JSON.parse(localStorage.getItem('PageEntry2'));
    /* 体温入力ページ 入力後 */
    this.PageEntry3 = JSON.parse(localStorage.getItem('PageEntry3'));
    /* 体温入力ページ 入力後 */
    this.PageOnLINE = JSON.parse(localStorage.getItem('PageOnLINE'));
    /* 体温入力ページ 入力後 */
    this.PageOnLINE1 = JSON.parse(localStorage.getItem('PageOnLINE1'));
    /* 体温入力ページ 入力後 */
    this.PageOnLINEnum = JSON.parse(localStorage.getItem('PageOnLINEnum'));
    /* 体温入力ページ 入力後 */
    this.PageOnLINE2 = JSON.parse(localStorage.getItem('PageOnLINE2'));
    /* 体温入力ページ 入力後 */
    this.PageOn2LINE = JSON.parse(localStorage.getItem('PageOn2LINE'));
    /* 体温入力ページ 入力後 */
    this.PageOnLINE3 = JSON.parse(localStorage.getItem('PageOnLINE3'));
    /* 体温入力ページ 入力後 */
    this.PageOn3LINE = JSON.parse(localStorage.getItem('PageOn3LINE'));
    /* 体温入力ページ 入力後 */
    this.taion = JSON.parse(localStorage.getItem('taion'));
    /* 体温入力ページ 入力後 */
    this.taionhyouzi = JSON.stringify(localStorage.getItem('taionhyouzi'));

  },
  /* ▲ ローカルストレージ ▲ */
  methods: {
    /*アプリを起動した時にメゾットを起動する*/
    window:onload = function() {
    //============================================
      //2回目以降の場合
      if(localStorage.getItem('$Data$Access')){

    //============================================
      //ローカルストレージに2回目以降のアクセスであることを記録する。
        localStorage.setItem('$Data$Access', 1);  //アクセスにデータを追加する。

      //============================================
      //日付を設定する。
        const dayscore = JSON.parse(localStorage.getItem('$Data$Temperature'));
        const DataDay = dayscore.length +1;  //初期起動は1日目なので、1を記録する。
        localStorage.setItem('$Data$Day', JSON.stringify(DataDay));

      //============================================
      //スクリーンを表示する設定。
        document.getElementById("dayday").style.display = "inline-block";
        document.getElementById("first").style.opacity = "0"; //画面を非表示にする。(初期化)
        document.getElementById("first").style.opacity ="1"  //画面を表示する。
        const dayscore2 = JSON.parse(localStorage.getItem("$Data$LastUpdates"));
        const dayscore2s = Date.now();
        const aa3 = dayscore2s - dayscore2;
        //localStorage.setItem('$Data$DayDayday', JSON.stringify(aa3));
        if(86400000 < aa3){
          document.getElementById("renderer7").style.opacity = "0"; //画面を非表示にする。(初期化)
          document.getElementById("renderer7").style.opacity ="1";  //画面を表示する。
        }
      }
    //============================================
      //1回目以降の場合
      else {
        this.DataDay = 1;
      //============================================
      //ローカルストレージに1回目のアクセスであることを記録する。
        localStorage.setItem('$Data$Access', 0);  //アクセスにデータを追加する。

      //============================================
      //日付関連の処理を行う。
        //30日間の日付を箱に追加する。
        const $Data$DaysList = [];  //箱を作成。
        for (let step = 0; step < 31; step++) { //配列を使って30日間繰り返す。
          const d = new Date();
          d.setDate(d.getDate() + step);
          //const year = d.getFullYear();
          //const month = d.getMonth() + 1;
          const day = d.getDate();
          const days = day + '日';
          //const $Data$DaysList = year + '年' + month + '月' + day + '日';
          $Data$DaysList.push(days);  //日付を追加する。
        }
        localStorage.setItem('$Data$DaysList', JSON.stringify($Data$DaysList)); //ローカルストレージに30日間の日付を追加する。

        //日付を設定する。
        const DataDay = 1;  //初期起動は1日目なので、1を記録する。
        localStorage.setItem('$Data$Day', JSON.stringify(DataDay));

        //最終更新日を設定する。
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;//0-11を取得するから+1する。
        const day = d.getDate() -1;
        const $Data$LastUpdate = year + '年' + month + '月' + day + '日';
        const $Data$LastUpdates = d.getTime();
        console.log($Data$LastUpdates);
        localStorage.setItem('$Data$LastUpdate', JSON.stringify($Data$LastUpdate)); //最終更新日を追加する。
        localStorage.setItem('$Data$LastUpdates', JSON.stringify($Data$LastUpdates)); //最終更新日を追加する。

      //============================================
      //毎日の体温を記録する箱を作成してローカルストレージに記録する。
        const $Data$Temperature = []; //箱を作成。
        localStorage.setItem('$Data$Temperature', JSON.stringify($Data$Temperature)); //ローカルストレージに登録。

      //============================================
      //スクリーンを表示する設定。
        document.getElementById("dayday2").style.display = "inline-block";
        document.getElementById("first").style.opacity = "0"; //画面を非表示にする。(初期化)
        document.getElementById("first").style.opacity ="1";  //画面を表示する。
      }
    },
    //============================================
      //「体温を記録する」のボタンを押した後の処理
    PageEntryBtn10On: function (){
      this.born = !this.born;
      document.getElementById("renderer6").style.opacity ="1";
      const renderer = document.getElementById("renderer6");
      renderer.style.opacity ="0";
      const renderer2 = document.getElementById("renderer12");
      renderer2.style.opacity ="1";
      document.getElementById("canvas").style.opacity ="0";
      const renderer4 = document.getElementById("canvas");
      renderer4.style.opacity ="1";
      //const hiyoko = [];//ひよこの箱
      const characterlist = "../assets/character01.png";
      const hiduke = new Date();
      const year = hiduke.getFullYear();
      const month = hiduke.getMonth()+1;
      const day = hiduke.getDate();
      const characterdata = year+"年"+month+"月"+day+"日";

      //["character01.png"];//ひよこリスト
      //const messageNo = Math.floor( Math.random() * characterlist.length);
      //const messagesOn = characterlist[messageNo];
      //const character = messagesOn
      //hiyoko.push(character);
      //hiyoko.push(characterlist);
      // localStorage.setItem('$Data$Character', JSON.stringify(characterlist));
      // localStorage.setItem('$Data$Character2', JSON.stringify(characterdata));
      this.characterlists = characterlist;
      this.characterdatas = characterdata;
      // localStorage.setItem('$Data$Character', JSON.stringify(characterlist));
      // localStorage.setItem('$Data$Character2', JSON.stringify(characterdata));
    },

    //============================================
      //「体温を記録する」のボタンを押した後の処理
      $Enter$Temperature: function (){
        //最終更新日チェック
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;//0-11を取得するから+1する。
        const day = d.getDate();
        const $Data$LastUpdate = year + '年' + month + '月' + day + '日';
        const last = JSON.parse(localStorage.getItem('$Data$LastUpdate'));

        // 入力済みか否かを確かめる。
        const last2 = $Data$LastUpdate
        if (last2 === last){ //同じ日の場合は入力済みと表示
          this.PageEntryd = !this.PageEntryd
        }
        else{  //違う日の場合は入力画面を表示する。
          this.PageEntry = !this.PageEntry;
        }
      },
      //体温を入力した時の処理
      $Enter$Temperature$On: function (){
        //最終更新日チェック
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;//0-11を取得するから+1する。
        const day = d.getDate();
        const $Data$LastUpdate = year + '年' + month + '月' + day + '日';
        const $Data$LastUpdates = d.getTime();
        localStorage.setItem('$Data$LastUpdate', JSON.stringify($Data$LastUpdate)); //最終更新日を更新する。
        localStorage.setItem('$Data$LastUpdates', JSON.stringify($Data$LastUpdates)); //最終更新日を追加する。

        //ページを閉じる
        this.PageEntry = !this.PageEntry;

      //============================================
      //毎日の体温を記録する箱を作成してローカルストレージに記録する。
        const $Data$Temperature = JSON.parse(localStorage.getItem('$Data$Temperature')); //体温を読み取る。
        $Data$Temperature.push(this.taion);
        localStorage.setItem('$Data$Temperature', JSON.stringify($Data$Temperature)); //ローカルストレージに登録。

      //============================================
      //日付を設定する。
        const dayscore = JSON.parse(localStorage.getItem('$Data$Temperature'));
        const DataDay = dayscore.length +1;  //初期起動は1日目なので、1を記録する。
        localStorage.setItem('$Data$Day', JSON.stringify(DataDay));

      //============================================
      //WebGLの表示についての処理
        if(29 < this.DataDay){//生まれる
          document.getElementById("renderer12").style.opacity ="0";
          const renderer = document.getElementById("renderer12");
          renderer.style.opacity ="1";
          //生まれる
          this.born = !this.born;
          document.getElementById("canvas").style.opacity ="1";
          const renderer4 = document.getElementById("canvas");
          renderer4.style.opacity ="0";
        }
        else if (this.taion < 36 ){
          document.getElementById("renderer0").style.opacity ="0";
          const renderer = document.getElementById("renderer0");
          renderer.style.opacity ="1";
          const dayscore = JSON.parse(localStorage.getItem('$Data$Temperature'));
          const daysss = dayscore.reduce(function(a, x){return a + ((x || 0) - 0);}, 0);
          localStorage.setItem('$Data$Day2', JSON.stringify(daysss));
          const dayscore2 = JSON.parse(localStorage.getItem('$Data$Day2'));
          const dayscore3 = dayscore2 / dayscore.length;
          localStorage.setItem('$Data$Day3', JSON.stringify(dayscore3));

          //ページを表示$Data$Day2
          this.PageEntry1 = !this.PageEntry1;

        }
        else if (this.taion < 40 ){
          document.getElementById("renderer5").style.opacity ="0";
          const renderer = document.getElementById("renderer5");
          renderer.style.opacity ="1";
          const dayscore = JSON.parse(localStorage.getItem('$Data$Temperature'));
          const daysss = dayscore.reduce(function(a, x){return a + ((x || 0) - 0);}, 0);
          localStorage.setItem('$Data$Day2', JSON.stringify(daysss));
          const dayscore2 = JSON.parse(localStorage.getItem('$Data$Day2'));
          const dayscore3 = dayscore2 / dayscore.length;
          localStorage.setItem('$Data$Day3', JSON.stringify(dayscore3));
          //ページを表示
          this.PageEntry1 = !this.PageEntry1;
        }
        else if (this.taion < 50 ){
          document.getElementById("renderer10").style.opacity ="0";
          const renderer = document.getElementById("renderer10");
          renderer.style.opacity ="1";
          const dayscore = JSON.parse(localStorage.getItem('$Data$Temperature'));
          const daysss = dayscore.reduce(function(a, x){return a + ((x || 0) - 0);}, 0);
          localStorage.setItem('$Data$Day2', JSON.stringify(daysss));
          const dayscore2 = JSON.parse(localStorage.getItem('$Data$Day2'));
          const dayscore3 = dayscore2 / dayscore.length;
          localStorage.setItem('$Data$Day3', JSON.stringify(dayscore3));
          //ページを表示
          this.PageEntry1 = !this.PageEntry1;
        }
      },
      //ダジャレのページ1
      PageEntryBtn5On: function (){
        // const aaa = this.taionhyouzi
        // aaa.push(this.taion);
        this.PageEntry1 = !this.PageEntry1;
        this.PageEntry3 = !this.PageEntry3;
        const renderer1 = document.getElementById("renderer0");
        renderer1.style.opacity ="0";
        const renderer3 = document.getElementById("renderer5");
        renderer3.style.opacity ="0";
        const renderer = document.getElementById("renderer10");
        renderer.style.opacity ="0";
        const renderer6 = document.getElementById("renderer11");
        renderer6.style.opacity ="1";
      },
      //ダジャレのページ2
      PageEntryBtn6On: function (){
        this.PageEntry3 = !this.PageEntry3;
        this.PageEntry4 = !this.PageEntry4;
      },
      //ダジャレのページ3
      PageEntryBtn7On: function (){
        const renderer1 = document.getElementById("renderer11");
        renderer1.style.opacity ="0";
        this.PageEntry4 = false;
        const renderer6 = document.getElementById("renderer7");
        renderer6.style.opacity ="1";

        window.setTimeout(function(){
          const renderer6 = document.getElementById("renderer7");
          renderer6.style.opacity ="0";
        }, 3000);
      },

      /* グラフページ */
      PageGraphBtnOn: function(){
        const data = JSON.parse(localStorage.getItem('$Data$Temperature'));
        const sum = data.reduce(function(a,b){
          return a + b;
        });
        const ave = sum / data.length;
        console.log(ave);
        localStorage.setItem("average", JSON.stringify(ave));

        this.showChild = false;
        this.$nextTick(() => (this.showChild = true));
        this.PageGraph = !this.PageGraph;
      },

      /* ひよこページ */
      PageHiyokoBtnOn: function (){
        this.PageHiyoko = !this.PageHiyoko;
      },


      /* 体温を既に入力済み */
      PageEntryBtn3On: function (){
        const renderer1 = document.getElementById("renderer8");
        renderer1.style.opacity ="0";
        const renderer2 = document.getElementById("renderer9");
        renderer2.style.opacity ="0";
        const renderer3 = document.getElementById("renderer10");
        renderer3.style.zIndex ="-100000";
        renderer3.style.opacity ="0";
      },
      PageEntryBtn4On: function (){
        this.PageEntry1 = false;
        this.PageEntry3 = !this.PageEntry3;
      },

      //LINE
      PageOn: function (){
        //未入力
        if (this.PageOnLINEnum < 1){
          this.PageOnLINE = !this.PageOnLINE;
        }
        // 入力後
        else if (this.PageOnLINEnum < 2){
          this.PageOn2LINE = !this.PageOn2LINE;
        }
        // 入力済み
        else if (this.PageOnLINEnum < 3){
          this.PageOn3LINE = !this.PageOn3LINE;
        }
      },
      $Enter$Temperature3: function (){
        this.PageOnLINE1 = !this.PageOnLINE1;
        this.PageOnLINE = false;
        this.PageOnLINEnum = 1;
      },
      PageOn2: function (){
        this.PageOnLINE2 = !this.PageOnLINE2;
        this.PageOnLINE1 = false;
        this.PageOnLINEnum = 1;
      },
      PageOn4: function (){
        this.PageOn2LINE = !this.PageOn2LINE;
      },
      $Enter$Temperature4: function (){
        this.PageOn2LINE = !this.PageOn2LINE;
        this.PageOnLINEnum = 2;
        const url = 'https://line.me/R/oaMessage/@933hghqe/?' + this.message;
        window.location.href = url
        window.open(url, '_blank');
      },
      PageOn3: function (){
        this.PageOn3LINE = !this.PageOn3LINE;
        this.PageOnLINE2 = false;
      },
      $Enter$Temperature5: function (){
        this.PageOn3LINE = !this.PageOn3LINE;
        this.PageOnLINEnum = 1;
        const url = 'https://line.me/R/oaMessage/@933hghqe/?' + 'キャンセル';
        window.location.href = url
        window.open(url, '_blank');
      },
      hiyokomodal: function (){
        this.hiyokomodal2 = !this.hiyokomodal2;
      },
      hiyokoclose: function (){
        this.hiyokomodal2 = !this.hiyokomodal2;
      },


    Entry: function (){
      //LINE友達登録前
      if(this.Num < 1){
        this.PageLINEEntry = !this.PageLINEEntry;
      }
      else if(this.Num < 2){
        this.PageFunctionEntry = !this.PageFunctionEntry;
        // const url = 'https://line.me/R/oaMessage/@933hghqe/?' + this.message;
        // window.location.href = url
        // window.open(url, '_blank');
      }
      else if(this.Num < 3){
        this.PageTimeEntry = !this.PageTimeEntry;
      }
      else if(3 <= this.Num){
        this.PageENDEntry = !this.PageENDEntry;
      }
    },
    Entry2: function (){
      //LINE友達登録前
      if(this.Num2 < 1){
        this.PageLINEEntry = !this.PageLINEEntry;
      }
      else if(this.Num2 < 2){
        this.PageFunctionEntry = !this.PageFunctionEntry;
        // const url = 'https://line.me/R/oaMessage/@933hghqe/?' + this.message;
        // window.location.href = url
        // window.open(url, '_blank');
      }
      else if(this.Num2 < 3){
        this.PageEND2Entry = !this.PageEND2Entry;
      }
    },

    EntryNum: function(){
      this.Num = this.Num +1;
      this.Num2 = this.Num2 +1;
    },
    EntryNum2: function(){
      this.Num = this.Num -1;
    },

    GraphBtn: function (){
      this.GraphPage = !this.GraphPage;
    },
    FunctionBtn: function (){
      this.FunctionPage = !this.FunctionPage;
    },
    FunctionBtn2: function (){
      this.LinePageOn = !this.LinePageOn;
    },
    event1: function(){
      this.linenum = this.linenum +1;
    },
    LineBtn: function (){
      if (this.linenum < 2){
        this.LinePageOn = !this.LinePageOn;
      }
      else{
        this.FunctionPage = !this.FunctionPage;
      }
    },

  },
  computed: {
    count1 () {
      return this.$store.state.count1
    }
  },
  props: {
    name: String,
    data: Array,     //←追加
    label: Array     //←追加
  },
  components: {
    Chart,/*グラフを追加する。*/
    Egg
  }
}
</script>