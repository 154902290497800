<!--templateタグは入れてはいけない-->
<script>
//インポートを宣言してextendする。Vueらしい書き方で進めます。
import { Line } from 'vue-chartjs'
import chartjsPluginAnnotation from 'chartjs-plugin-annotation'

export default {
  extends: Line,//インポートしたものを選択します。
  type: 'line',
  props: [
   'variable',
   'heikin'
  ],
  data () {
    return {
      mixins: [Line.reactiveProp],
      taion: 0,
      data: {
        labels: JSON.parse(localStorage.getItem("$Data$DaysList")),
        datasets: [
          {
            label: '体温(℃）',
            //体温を配列で取得
            data:JSON.parse(localStorage.getItem('$Data$Temperature')),
            borderColor: "rgba(255,0,0,1)",
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 1,
            type: 'line'
          }
        ],
        datacollection: {},
        height: 197,
        width: 335,
        //taion: [JSON.parse(localStorage.getItem('taionhyouzi'))],
      },
      options: {

        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: true,
          // text: '体温（1月12日~2月10日）'
        },
        scales: {
          yAxes: [{
            id:'yy',
            ticks: {
              suggestedMax: 40,
              suggestedMin: 32,
              stepSize: 1,
              callback: function(value, index, values){
                return  value +  '℃'
              }
            }
          }]
        },
        annotation: {
          annotations: [
            {
                type: 'line', // 線分を指定
                drawTime: 'afterDatasetsDraw',
                id: 'a-line-2', // 線のid名を指定（他の線と区別するため）
                mode: 'horizontal', // 水平を指定
                scaleID: 'yy', // 基準とする軸のid名
                value: JSON.parse(localStorage.getItem("$Data$Day3")), // 引きたい線の数値（始点）
                endValue: JSON.parse(localStorage.getItem("$Data$Day3")), // 引きたい線の数値（終点）
                borderColor: 'rgba(60,200,60,0.8)', // 線の色
                borderWidth: 3, // 線の幅（太さ）
                borderDash: [2, 2],
                borderDashOffset: 1,
                label: { // ラベルの設定
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    bordercolor: 'rgba(60,200,60,0.8)',
                    borderwidth: 2,
                    fontSize: 10,
                    fontStyle: 'bold',
                    fontColor: '#333',
                    xPadding: 10,
                    yPadding: 10,
                    cornerRadius: 3,
                    position: 'right',
                    xAdjust: 0,
                    yAdjust: 0,
                    enabled: true,
                    content: '平均体温'
                }
            }
          ]
        },
      }
    }
  },
  // watch: {
  //   taion: {
  //     //数値を書き込む
  //     handler: function () {
  //       localStorage.setItem('taion', this.taion);
  //     },
  //     deep: true,
  //   },
  //   taionhyouzi: {
  //     //数値を書き込む
  //     handler: function () {
  //       localStorage.setItem('taionhyouzi', this.taionhyouzi);
  //     },
  //     deep: true,
  //   },
  // },
  //mountedをフックにして、this.renderChart()を呼び出す。 チャートインスタンスを描画する。
  mounted () {
    this.addPlugin(chartjsPluginAnnotation),
    this.renderChart(this.data, this.options)
    //this.taionhyouzi = JSON.parse(localStorage.getItem("taionhyouzi"))
    // this.taionhyouzi = JSON.stringify(localStorage.getItem("taionhyouzi"));
    //this.data.taionhyouzi.push(JSON.parse(localStorage.getItem('taionhyouzi'))) //|| [];
  },
  methods: {
    EntryNum: function(){
      this.Num = this.Num +1;
      this.Num2 = this.Num2 +1;
    }
  }
}
</script>