<template>
  <div id="app" style="width:100%;height:100%;">
    <Home></Home>
  </div>
</template>

<script>
import Home from "./views/Home";

export default {
  name: "app",
  components: {
    Home
  }
};
</script>

<style lang="scss">
#app {
  text-align: center;
  margin: 0;
}
</style>