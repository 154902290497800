import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// ストアを作成
// const state = {
//   count:0
// };
const store = new Vuex.Store({
  //厳格モード。開発する時だけ。
  strict: process.env.NODE_ENV !== 'production',
  state: {
    count: 0,
    count1: 0,
    PageGraphBtn:false
  },
  mutations: {
    // 追加
    PageGraphBtn (state) {
      state.PageGraphBtn = !state.PageGraphBtn
    },
    increment (state) {
      state.count += 1
    },
    decrement (state) {
      state.count -= 1
    }
  },
  actions: {
    increment (context) {
      context.commit('increment')
    },
    decrement ({ commit }) {
      commit('decrement')
    }
  },
  getters:{
    getCount (state) {
      return state.count
    }
  }
});

// const store = new Vuex.Store({
//   state: {
//     count: 0
//   },
//   mutations: {
//     // カウントアップするミューテーションを登録
//     increment(state) {
//       state.count++
//     }
//   }
// })
export default store